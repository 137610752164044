import { type ReactNode, useEffect, useState } from 'react';

import { AudienceControlMsgSenderProvider } from '../../components/Audience/AudienceControlProviders';
import { BotProvider } from '../../components/Bot';
import { BroadcastProvider } from '../../components/Broadcast/BroadcastProvider';
import { ChatSharedContextProvider } from '../../components/Chat/SharedContext';
import { CohostPositionManagerProvider } from '../../components/Cohost/CohostPositionManagerProvider';
import {
  ConfirmCancelModalProvider,
  ConfirmCancelModalRoot,
} from '../../components/ConfirmCancelModalContext';
import { ContextMenu } from '../../components/ContextMenu';
import { ContextMenuProvider } from '../../components/ContextMenu/Context';
import { CrowdFramesProvider } from '../../components/CrowdFrames';
import { DeviceStateWatchdog } from '../../components/Device';
import { HostDeviceCheckModal } from '../../components/Device/Check';
import { useCreateDefaultVideoStreamMixer } from '../../components/Device/video-stream-mixer';
import { ExperienceMetricsProvider } from '../../components/ExperienceMetrics';
import { ExperienceScoreProvider } from '../../components/ExperienceScore';
import {
  useFirebaseContext,
  useInitFirebase,
  useIsFirebaseConnected,
} from '../../components/Firebase';
import { FirebaseReconnectModal } from '../../components/Firebase/ReconnectModal';
import { FirebaseLatencyProvider } from '../../components/FirebaseLatency/Provider';
import { FrameRateMeasure } from '../../components/FrameRateMeasure/FrameRateMeasure';
import { BlockProviders } from '../../components/Game/Blocks';
import { BlockOutputsClearer } from '../../components/Game/Blocks/Common/block-outputs';
import { GameController } from '../../components/Game/Blocks/Common/Controller';
import { OndGamePlayOverlay } from '../../components/Game/Blocks/Common/GamePlay';
import { GamePlayRecordingCapableUIConfigurationProvider } from '../../components/Game/Blocks/Common/GamePlay/GamePlayUIConfigurationProvider';
import { GameSessionPreconfigProvider } from '../../components/Game/Blocks/Common/GamePlay/GameSessionPreconfigProvider';
import { BlockLifecycleRulesEvaluatorProvider } from '../../components/Game/Blocks/Common/LifecycleRules/BlockLifecycleRulesEvaluator';
import {
  GameLibrary,
  GameLibraryProvider,
} from '../../components/Game/GameLibrary';
import { GameCoverClip } from '../../components/Game/GamePackCoverPres';
import { GameSessionBundle } from '../../components/Game/GameSessionBundle';
import { GameStreamingStatusProvider } from '../../components/Game/GameStreamingStatusProvider';
import { PostGameProvider } from '../../components/Game/PostGame/Provider';
import { PreGameProvider } from '../../components/Game/PreGame/Provider';
import { MusicShareModal } from '../../components/Game/ShareMusicModal';
import {
  GameLogMonitorBrandsBlocks,
  GameLogMonitorScoreboard,
  GameLogMonitorSession,
  GameLogMonitorTeamCreation,
  GameLogMonitorTeamNames,
  GameLogMonitorUserJoined,
  GameLogSynchronizeCommonProperties,
} from '../../components/GameLog/GameLogComponents';
import {
  BlockRecorderProvider,
  BlockRecordingFullScreenCountIn,
} from '../../components/GameRecorder/BlockRecorderProvider';
import { Header } from '../../components/Header';
import { HostIndicator } from '../../components/Host/HostIndicator';
import { MiddlePanel } from '../../components/Layout';
import { RightPanel } from '../../components/Layout/RightPanel';
import { LayoutAnchorProvider } from '../../components/LayoutAnchors/LayoutAnchors';
import { useLiteModeAutoDisable } from '../../components/LiteMode';
import { Lobby, LobbyProvider } from '../../components/Lobby';
import { MediaControls } from '../../components/MediaControls';
import { MusicPlayerProvider } from '../../components/MusicPlayer/Context';
import { MusicShareProvider } from '../../components/MusicShareContext';
import {
  MultipleUserInstancesWarning,
  useInitMultipleUserInstancesWatcher,
  useMultipleUserInstancesContext,
} from '../../components/MutipleInstances';
import { NotificationCenter } from '../../components/Notification';
import {
  NotificationContextProvider,
  useNotificationDataSourceRedux,
} from '../../components/Notification/Context';
import { OnlineGameTracking } from '../../components/Online/OnlineGameTracking';
import { PairingGameProvider } from '../../components/Pairing';
import { PersistentPointsProvider } from '../../components/PersistentPoints/Provider';
import { PixelFxProvider } from '../../components/PixelFx/PixelFxProvider';
import { PixelFxSceneProvider } from '../../components/PixelFx/PixelFxSceneProvider';
import { PlaybackModeProvider } from '../../components/PlaybackMode/Provider';
import { ProvidersList } from '../../components/ProvidersList';
import { RemoteRefreshProvider } from '../../components/RemoteRefresh/RemoteRefreshProvider';
import {
  RightPanelProvider,
  RightPanelTabState,
} from '../../components/RightPanelContext';
import {
  SessionTracking,
  useStreamSessionRecovery,
} from '../../components/Session';
import { SettingsModal } from '../../components/Settings/Settings';
import { type MenuKey } from '../../components/Settings/types';
import { SFXProvider } from '../../components/SFX';
import { Stage, useInitStage } from '../../components/Stage';
import { StreamingToolsProvider } from '../../components/StreamingTools/StreamingToolsContext';
import {
  SwitchNotice,
  SwitchNoticeProvider,
} from '../../components/SwitchNotice';
import {
  TeamRandomizer,
  TeamRandomizerProvider,
} from '../../components/TeamRandomizer';
import { TeamSizeContextProvider } from '../../components/TeamSizeControl/Context';
import { maxTeamMembers } from '../../components/TeamSizeControl/utils';
import {
  LiveGameAutoSwitchTownhallMode,
  LiveGameTownhallLargeGroupSwticher,
  TownhallProvider,
} from '../../components/Townhall';
import {
  UserRequired,
  useUser,
  useUserContext,
  useUserStates,
} from '../../components/UserContext';
import {
  useFirebaseVenueSyncing,
  useInitVenueEvent,
  useMarkVenueInitCompleted,
  useMyClientId,
  useMyClientType,
  useParamVenueId,
  useSubscribeVenueEvent,
  useVenue,
  VenueBootstrap,
  VenueContextProvider,
  VenueInitLoading,
  VenueInitProvider,
  VenueSetup,
} from '../../components/Venue';
import { VenueOrgLogoAverageColorProvider } from '../../components/VenueOrgLogoAverageColor/VenueOrgLogoAverageColorProvider';
import { VideoEffectsSettingsLocalStore } from '../../components/VideoEffectsSettings/Storage';
import { AdHocVOMsgReceiverProvider } from '../../components/VoiceOver/AdHocVOMsgProviders';
import {
  LocalizedVoiceoverPlayerProvider,
  LocalizedVoiceoversRTCPublisherProvider,
} from '../../components/VoiceOver/LocalizedVoiceOvers';
import { SubtitlesManagerProvider } from '../../components/VoiceOver/SubtitlesManagerProvider';
import {
  DebugStreams,
  useDummyHostRTCServiceMap,
  useHostRTCServiceMap,
  useInitWebRTCMVMProcessor,
} from '../../components/WebRTC';
import { RTCServiceContextProvider } from '../../components/WebRTC/RTCServiceContext';
import config from '../../config';
import { useEnsureUnlockedAudioContext } from '../../hooks/useEnsureUnlockedAudioContext';
import { getFeatureQueryParam } from '../../hooks/useFeatureQueryParam';
import { useUpdateLoggerWithStreamSessionId } from '../../hooks/useInitLogger';
import { useInitReduxSlice } from '../../hooks/useInitRedux';
import { useInitSentryContext } from '../../hooks/useInitSentryContext';
import {
  useInitGameSession,
  useInitStreamSession,
} from '../../hooks/useInitSession';
import { useInstance } from '../../hooks/useInstance';
import { useInterceptNavigatorMediaSessionKeys } from '../../hooks/useInterceptNavigatorMediaSessionKeys';
import { useAutoloadLastGame } from '../../hooks/useLoadGame';
import { usePageTitle } from '../../hooks/usePageTitle';
import { useEnsureHostPageRedirect } from '../../hooks/useRedirection';
import { useRSCmp } from '../../hooks/useRSCmp';
import { useUserWatcher } from '../../hooks/useUserWatcher';
import {
  getAudioContext,
  getEchoCancelledAudioDestination,
} from '../../services/audio/audio-context';
import { type ClientId } from '../../services/crowd-frames';
import { type Venue } from '../../types';
import { ClientType, type User } from '../../types/user';
import { HostLocalStreamView } from './HostLocalStreamView';
import { StreamControl } from './StreamControl';
import { useInitLiveGameHosting } from './useInitLiveGameHosting';
import { LiveGameTownhallModeToggle } from './Utiltities';

const Host = (): JSX.Element | null => {
  useRSCmp('host');

  const [showDeviceCheckModal, setShowDeviceCheckModal] = useState(true);
  const [showAudioShareModal, setShowAudioShareModal] = useState(false);
  const [showSettingsModal, setShowSettingsModal] = useState<boolean | MenuKey>(
    false
  );
  const user = useUser();
  const { joined } = useUserStates();
  const { updateUserStates } = useUserContext();
  const clientId = useMyClientId();
  const clientType = useMyClientType();

  const [venue] = useVenue();
  const { multipleInstances } = useMultipleUserInstancesContext();
  const firebaseConnected = useIsFirebaseConnected();
  const { svc } = useFirebaseContext();
  const notificationDataSource = useNotificationDataSourceRedux();
  const isController = true;

  useEffect(() => {
    if (joined) setShowDeviceCheckModal(false);
  }, [joined]);

  useEffect(() => {
    return () => {
      updateUserStates({ joined: false });
      setShowDeviceCheckModal(true);
    };
  }, [updateUserStates]);

  const isReady = joined && firebaseConnected;

  if (multipleInstances) {
    return (
      <MultipleUserInstancesWarning
        clientId={clientId}
        venueName={venue?.displayName}
      />
    );
  }

  const providers = [
    <ExperienceScoreProvider />,
    <SFXProvider />,
    <ConfirmCancelModalProvider />,
    <SwitchNoticeProvider />,
    <LayoutAnchorProvider />,
    <PixelFxProvider />,
    <HostRTCServiceProvider
      clientId={clientId as ClientId}
      readyToJoin={isReady}
    />,
    <PairingGameProvider svc={svc} />,
    <MusicShareProvider />,
    <CrowdFramesProvider
      liteModeEnabled={false}
      userId={user.id}
      myClientId={clientId as ClientId}
    />,
    <NotificationContextProvider datasource={notificationDataSource} />,
    <LobbyProvider ready={isReady} svc={svc} />,
    <PlaybackModeProvider />,
    <BlockRecorderProvider configAPIBaseURL={config.api.baseUrl} />,
    <RightPanelProvider
      initialTab={RightPanelTabState.FullChat}
      disableOutsideClickToClose
    />,
    <TeamRandomizerProvider venueId={venue.id} />,
    <MusicPlayerProvider
      audioContextGetter={getAudioContext}
      echoCancelledAudioDestinationGetter={getEchoCancelledAudioDestination}
    />,
    <TeamSizeContextProvider
      venueId={venue.id}
      defaultMaxMembers={maxTeamMembers}
    />,
    <ChatSharedContextProvider />,
    <RemoteRefreshProvider />,
    <ContextMenuProvider />,
    <StreamingToolsProvider />,
    <TownhallProvider ready={isReady} svc={svc} />,
    <BroadcastProvider ready={isReady} initialEnabled />,
    <GameLibraryProvider />,
    <SubtitlesManagerProvider venueId={venue.id} />,
    <PreGameProvider />, // Townhall and GameLibrary depend on this
    <PostGameProvider />, // GameLibrary depends on this
    <GamePlayRecordingCapableUIConfigurationProvider />,
    <GameSessionPreconfigProvider />,
    <ExperienceMetricsProvider isReady={isReady} />,
    <FirebaseLatencyProvider
      isReady={isReady}
      clientId={clientId as ClientId}
      firebaseService={svc}
    />,
    <BotProvider />,
    <GameStreamingStatusProvider />,
    <AdHocVOMsgReceiverProvider venueId={venue.id} />,
    <CohostPositionManagerProvider ready={isReady} svc={svc} />,
    <AudienceControlMsgSenderProvider venueId={venue.id} />,
    <LocalizedVoiceoversRTCPublisherProvider
      venueId={venue.id}
      readyToJoin={isReady}
    />,
    <LocalizedVoiceoverPlayerProvider
      venueId={venue.id}
      readyToJoin={isReady}
    />,
    <BlockLifecycleRulesEvaluatorProvider />,
    <VenueOrgLogoAverageColorProvider />,
  ];

  const providerful = (
    <ProvidersList providers={providers}>
      <VenueSetup />
      <GameLogSynchronizeCommonProperties venueId={venue.id} />
      <GameLogMonitorUserJoined />
      <GameLogMonitorScoreboard />
      <GameLogMonitorBrandsBlocks />
      <GameLogMonitorTeamCreation />
      <GameLogMonitorTeamNames />
      <GameLogMonitorSession />
      <BlockOutputsClearer venueId={venue.id} svc={svc} />
      <div className='h-full w-full'>
        <Header pinLeft={showDeviceCheckModal} enableRight />
        {showDeviceCheckModal && <HostDeviceCheckModal />}
        {showAudioShareModal && (
          <MusicShareModal onClose={() => setShowAudioShareModal(false)} />
        )}
        <HostLocalStreamView />
        {isReady && (
          <ProvidersList
            providers={[
              <PersistentPointsProvider userId={user.id} />,
              <PixelFxSceneProvider />,
            ]}
          >
            <StreamControl zIndex='z-35' />
            <HostIndicator zIndex='z-35' />
            <Lobby />
            <MiddlePanel className='z-30' />
            <BlockProviders>
              <GameController />
              <BlockRecordingFullScreenCountIn zIndex='z-35' />
              <OndGamePlayOverlay />
              <GameSessionBundle />
            </BlockProviders>
            <GameLibrary />
            <RightPanel chat='enabled' people='enabled' />
            <Stage />
          </ProvidersList>
        )}
        {isReady && <TeamRandomizer />}
        {isReady && (
          <MediaControls
            openSettingsModal={(key?: MenuKey | boolean) =>
              setShowSettingsModal(key ? key : true)
            }
          />
        )}
        <ContextMenu />
        <DebugStreams />
        <DeviceStateWatchdog />
        {showSettingsModal && (
          <SettingsModal
            initialMenuKey={
              typeof showSettingsModal === 'boolean'
                ? undefined
                : showSettingsModal
            }
            onClose={() => setShowSettingsModal(false)}
          />
        )}
        <ConfirmCancelModalRoot />
        <SwitchNotice />
        {isReady && <SessionTracking config={config.session} />}
        {isReady && <OnlineGameTracking />}
        <FrameRateMeasure />
        <NotificationCenter
          className='min-w-100'
          toastClassName='relative rounded-xl mb-2'
          position='top-center'
          transition='lp'
          newestOnTop
          autoClose={false}
          limits={3}
        />
        <LiveGameTownhallModeToggle zIndex='z-40' />
        <LiveGameAutoSwitchTownhallMode />
        <LiveGameTownhallLargeGroupSwticher />
        {joined && <FirebaseReconnectModal />}
        <GameCoverClip id='game-cover-clip' />
      </div>
    </ProvidersList>
  );

  return (
    <>
      <ReturnlessHooks
        user={user}
        venue={venue}
        clientId={clientId}
        clientType={clientType}
        isController={isController}
        firebaseConnected={firebaseConnected}
        joined={joined}
      />
      {providerful}
    </>
  );
};

// eslint-disable-next-line import/no-default-export
export default function HostWrapper(): JSX.Element {
  const mixer = useCreateDefaultVideoStreamMixer();
  const vesStore = useInstance(() => {
    const store = new VideoEffectsSettingsLocalStore(true);
    store.load();
    return store;
  });
  return (
    <VenueInitProvider>
      <VenueContextProvider paramVenueId={useParamVenueId()}>
        {(venueId) => {
          return (
            <VenueBootstrap
              venueId={venueId}
              clientType={ClientType.Host}
              providers={[<UserRequired />]}
              mixer={mixer}
              vesStore={vesStore}
            >
              <Host />
            </VenueBootstrap>
          );
        }}
      </VenueContextProvider>
      <VenueInitLoading />
    </VenueInitProvider>
  );
}

function ReturnlessHooks(props: {
  user: User;
  venue: Venue;
  clientId: string;
  clientType: ClientType;
  isController: boolean;
  firebaseConnected: boolean;
  joined: boolean;
}) {
  const {
    user,
    venue,
    clientId,
    clientType,
    isController,
    firebaseConnected,
    joined,
  } = props;

  usePageTitle(user, venue, true);
  useInterceptNavigatorMediaSessionKeys();
  useEnsureHostPageRedirect(user, venue);
  useInitLiveGameHosting(clientId);
  useUserWatcher(clientId, clientType, user);
  useUpdateLoggerWithStreamSessionId();
  useInitFirebase(!!user.id && !!venue.id);
  useInitReduxSlice(clientId, venue.id);
  useInitMultipleUserInstancesWatcher(clientId, clientType);
  useEnsureUnlockedAudioContext();
  useAutoloadLastGame();
  useInitSentryContext(venue.id);
  useInitStreamSession(isController);
  useInitGameSession({
    isController: isController,
    initialSetup: useInstance(() => ({ isLiveGame: true })),
  });
  useStreamSessionRecovery(joined, true);
  useInitStage();
  useLiteModeAutoDisable();
  useInitVenueEvent();
  useFirebaseVenueSyncing(true);
  useMarkVenueInitCompleted(!!user.id && firebaseConnected);
  useSubscribeVenueEvent();

  return null;
}

function HostRTCServiceProvider(props: {
  clientId: string;
  readyToJoin: boolean;
  children?: ReactNode;
}) {
  const { clientId, readyToJoin } = props;
  const hostRTCServiceMap = useHostRTCServiceMap(clientId);
  const dummyRTCServiceMap = useDummyHostRTCServiceMap(clientId);
  const rtcServiceMap = getFeatureQueryParam('stream-use-dummy')
    ? dummyRTCServiceMap
    : hostRTCServiceMap;

  useInitWebRTCMVMProcessor(rtcServiceMap.stage);

  return (
    <RTCServiceContextProvider
      readyToJoin={readyToJoin}
      rtcServiceMap={rtcServiceMap}
    >
      {props.children}
    </RTCServiceContextProvider>
  );
}
